const education = [
  {
    title: "B.Sc in Computer Science",

    data: [],
  },
  {
    title: "Relevant Courses",

    data: [
      "Software Construction",
      "Algorithms and Data Structures",
      "Models of Computation",
      "Introduction to Software Engineering",
      "Intermediate Algorithm Design and Analysis",
      "Introduction to AI",
    ],
  },
];

export default education;
